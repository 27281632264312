
import { computed, defineComponent, onMounted, ref, unref, watch, ComputedRef } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useOrders, useUpdateRefund } from '@/composables/api';
import { ElMessageBox, ElMessage, ElForm } from 'element-plus';
import { invert, delay } from 'lodash';

import SearchBox from '@/components/keyword-searching/Index.vue';
import Filter, { FilterEvent, FilterOption, FilterType } from '@/components/filter/Index.vue';
import {
  MAX_PER_PAGE, getPlans,
  getRefund, ResponseError, OrderSortKey, RefundType, RefundTypeEnum, syncOrder
} from '@/services/api';
import useFormErrors from '@/utils/validate';
import { OrderStatus, Plan, PartialOrder, Order, OrderType, Refund } from '@/interfaces';
import { formatLocalTime, isTimeBefore, formatISOString } from '@/utils/format-time';
import { getThousandSeparator } from '@/utils/render';
import permissionUnits from '@/components/permission-units/index.vue';
import { useKeyword, usePage, useSort } from '@/composables';
import { SORT_MAP } from '@/views/constants';
import { normalizeRequestData } from '@/utils/normalization';

const translate = {
  UNPAID: '尚未付款',
  SUCCESS: '付款成功',
  FAIL: '付款失敗',
  EXPIRED: '訂單逾期',
  REFUNDED: '已退款',
  REFUNDING: '等待退款'
};

const DEFAULT_FORM_VALUES = {
  refundType: RefundTypeEnum.FULL,
  refundReason: ''
};

const REQUIRED_RULE = {
  required: true,
  message: '此為必填欄位'
};

const rules = {
  refundType: [REQUIRED_RULE],
  refundReason: [REQUIRED_RULE]
};

export default defineComponent({
  components: {
    SearchBox,
    Filter,
    permissionUnits
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const { keyword } = useKeyword();
    const { setPageQuery, page, pageSize } = usePage();

    const OrderCreatedDateRange = ref(null);
    const createdStartAt = ref('');
    const createdEndAt = ref('');
    const paymentGateway = ref('');
    const status = ref();
    const planId = ref();
    const plans = ref<Plan[]>([]);
    const planOptions = ref([]);
    const defaultTime = ref([new Date(2000, 1, 1, 0, 0, 0), new Date(2000, 2, 1, 23, 59, 59)]);
    const showDialog = ref(false);

    const formRef = ref<typeof ElForm>();
    const formValues = ref({ ...DEFAULT_FORM_VALUES });
    const notifyText = {
      [RefundTypeEnum.FULL]: '此退款操作為「全額退款」，系統會退回全額款項。',
      [RefundTypeEnum.PARTIAL]: '此退款操作為「按未使用天數」，系統會退回全額款項後按照已使用天數立即執行補單。'
    };

    const { formErrors, bindFormItemError } = useFormErrors();
    const { sortKey, sortOrder } = useSort<OrderSortKey>();
    const { data, isLoading, isFetching, refetch } = useOrders({
      page,
      keyword,
      pageSize,
      createdStartAt,
      createdEndAt,
      status,
      planId,
      sort: sortOrder,
      sortKey,
      paymentGateway
    });
    const { isLoading: updateRefundLoading, mutate: updateRefund } = useUpdateRefund();

    const dialogData = ref<Refund>({} as Refund);
    const orderId = ref();
    const orderType = {
      initial: '新戶',
      rebill: '續訂',
      refund_recharge: '補單',
      quick_verify: '快速驗證'
    };

    const fetchPlans = async() => {
      plans.value = (await getPlans({ query: { pageSize: MAX_PER_PAGE } })).data;
    };

    const handleSortChange = ({ prop, order }: {
      prop: OrderSortKey
      order: 'ascending' | 'descending'
      }) => {
      sortKey.value = prop || undefined;
      sortOrder.value = SORT_MAP[order];
    };

    const handleFilterChange = (event: FilterEvent) => {
      // mutate ref
      status.value = event[0];
      planId.value = event[1];
      paymentGateway.value = event[2];
    };

    const handleDateChange = () => {
      if (!OrderCreatedDateRange.value) {
        createdStartAt.value = '';
        createdEndAt.value = '';

        return;
      }
      createdStartAt.value = formatISOString(OrderCreatedDateRange.value[0]);
      createdEndAt.value = formatISOString(OrderCreatedDateRange.value[1]);
    };

    onMounted(() => {
      fetchPlans();
    });

    watch(plans, () => {
      planOptions.value = plans.value.map(({ name, id }) => ({ label: `${name}`, value: `${id}` }));
    });

    const filterOptions: ComputedRef<FilterOption[]> = computed(() => {
      return [
        {
          type: FilterType.SELECTOR,
          label: '訂單狀態',
          placeholder: '請選擇',
          options: Object
            .entries(OrderStatus)
            .map(([label, value]) => ({ label: translate[label], value }))
        },
        {
          type: FilterType.SELECTOR,
          label: '方案',
          placeholder: '請選擇',
          options: planOptions.value
        },
        {
          type: FilterType.SELECTOR,
          label: '金流平台',
          placeholder: '請選擇',
          options: [
            {
              label: '全部',
              value: ''
            },
            {
              label: 'Joylucky',
              value: 'joylucky'
            },
            {
              label: 'Gash',
              value: 'gash'
            }
          ]
        }
      ];
    });

    const onRefund = async() => {
      showDialog.value = true;
      const { data } = await getRefund({
        orderId: orderId.value,
        query: {
          refundType: formValues.value.refundType
        }
      });
      dialogData.value = data;
    };

    const fetchRefund = (rowId?: number) => {
      if (rowId) orderId.value = String(rowId);
      onRefund();
    };

    const closeDialog = () => {
      dialogData.value = {} as Refund;
      orderId.value = null;
    };

    const actionDisabled = (row: PartialOrder) => {
      const res = {
        refund: false,
        receipt: false
      };

      switch (row.status) {
        case OrderStatus.UNPAID:
          res.refund = true;
          res.receipt = true;
          return res;
        case OrderStatus.SUCCESS:
          // 付款成功「不包含」，7days的1元訂單，以及補單的訂單。
          if (row.type === OrderType.REFUND_RECHARGE || row.plan?.name === '7 Days') {
            res.refund = true;
          } else {
            res.refund = false;
          }
          res.receipt = false;
          return res;
        case OrderStatus.FAIL:
          res.refund = true;
          res.receipt = true;
          return res;
        case OrderStatus.EXPIRED:
          res.refund = true;
          res.receipt = true;
          return res;
        case OrderStatus.REFUNDED:
          res.refund = true;
          res.receipt = false;
          return res;
        case OrderStatus.REFUNDING:
          res.refund = false;
          res.receipt = false;
          return res;
        default:
          return res;
      }
    };

    const isOrderExpired = (row: PartialOrder) => {
      return !isTimeBefore(row?.subscription?.endedAt) && row.status !== OrderStatus.REFUNDING;
    };

    const refundSubmitForm = () => {
      formErrors.value = {};
      const form = unref(formRef);

      form && form.validate((valid: boolean) => {
        if (valid) {
          updateRefund({ query: { orderId: orderId.value }, data: normalizeRequestData(formValues.value) }, {
            onSuccess() {
              refetch.value();
              showDialog.value = false;
            },

            onError(error: ResponseError) {
              ElMessage.error(error.response?.data.message);
              formErrors.value = error.response?.data.message;
            }
          });
        }
      });
    };

    const handleOrderSync = async(row: Order & { disabled: boolean }) => {
      row.disabled = true;
      try {
        await syncOrder({ orderId: String(row.id) });
        ElMessageBox.alert('同步訂單狀態為成功，請再確認該會員是否有重複訂閱', '', {
          confirmButtonText: '確定'
        });
      } catch (error) {
        ElMessageBox.alert((error as ResponseError).response?.data.message || '未知錯誤，請聯繫系統管理員', '', {
          confirmButtonText: '確定'
        });
      }
      // 防連點機制：同一筆訂單的 recall 按鈕點擊後倒數 5s 才可再次點擊
      delay(() => {
        row.disabled = false;
      }, 5000);
    };

    // cache搜索條件在querystring
    watch([createdStartAt, createdEndAt], ([createdStartAt, createdEndAt]) => {
      router.push({ query: { ...route.query, createdStartAt, createdEndAt } });
    });
    // 從querystring帶入搜索條件
    watch(
      () => route.query,
      (query) => {
        createdStartAt.value = query.createdStartAt as string;
        createdEndAt.value = query.createdEndAt as string;

        if (createdStartAt.value && createdEndAt.value) {
          OrderCreatedDateRange.value = [createdStartAt.value, createdEndAt.value];
          return;
        }
        OrderCreatedDateRange.value = null;
      },
      { immediate: true }
    );

    return {
      page,
      data,
      plans,
      isLoading,
      isFetching,
      OrderCreatedDateRange,
      filterOptions,
      OrderStatus,
      planOptions,
      defaultTime,
      keyword,
      handleDateChange,
      handleSortChange,
      handleFilterChange,
      formatLocalTime,
      getThousandSeparator,
      showDialog,
      refundSubmitForm,
      actionDisabled,
      fetchRefund,
      closeDialog,
      dialogData,
      isTimeBefore,
      orderType,
      isOrderExpired,
      updateRefundLoading,
      setPageQuery,
      defaultSort: computed(() => ({
        prop: sortKey.value,
        order: invert(SORT_MAP)[sortOrder.value]
      })),
      formRef,
      formValues,
      notifyText,
      rules,
      bindFormItemError,
      RefundTypeEnum,
      router,
      orderId,
      handleOrderSync
    };
  }
});
